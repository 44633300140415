import { type ChangeEvent } from "react";
import NextLink from "next/link";
import {
  Text,
  Loader,
  Box,
  Select,
  Button,
  Heading,
  PopOver,
  IconFa,
  Link as A,
} from "@cruk/cruk-react-components";
import { useRouter } from "next/compat/router";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { fwsUrlTeamMembers } from "@fwa/src/services/teamService";
import { useTracking } from "@fwa/src/hooks/useTracking";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { queryAsString } from "@fwa/src/utils/urlUtils";

import { TeammMembersOverviewPodium } from "@fwa/src/components/TeamMembersOverviewPodium";
import { CopyToClipBoard } from "@fwa/src/components/CopyToClipboard";
import { MemberFundraisingPageDonate } from "@fwa/src/components/MemberFundraisingPageDonate";

import {
  MemberList,
  FilterWrapper,
  Row,
  RowCenter,
} from "@fwa/src/components/styles";
import {
  AvatarWrapper,
  ButtonWrapper,
  FullWidthChild,
  ShadowWrapper,
  PodiumLinkWrapper,
} from "@fwa/src/components/TeamMembersOverview/styles";

import {
  type TeamPageType,
  type TeamMemberType,
  type TeamMemberSortType,
  type FwsResponseData,
} from "@fwa/src/types";

const joinTeamSVG = "/assets/images/img/join-team.svg";
const MAX_ITEMS = 5;

type Props = {
  page: TeamPageType;
};

export const TeamMembersOverview = ({ page }: Props) => {
  const { trackEventGtm, trackError } = useTracking();
  const router = useRouter();
  const { sort: sortQuery } = router?.query || {};
  const sort = (queryAsString(sortQuery) || "leader") as TeamMemberSortType;
  const url = page
    ? fwsUrlTeamMembers({
        teamId: page.uniqueId,
        currentPage: 1,
        itemsPerPage: MAX_ITEMS,
        sort,
      })
    : null;
  const { data, error, totalItems } = useSwrGet<
    FwsResponseData<TeamMemberType>
  >(url, {});
  const memberships = data?.results || [];

  const top3MembersUrl = page
    ? fwsUrlTeamMembers({
        teamId: page.uniqueId,
        currentPage: 1,
        itemsPerPage: 3,
        sort: "amount",
      })
    : null;

  const { data: top3Members, error: top3MembersError } = useSwrGet<
    FwsResponseData<TeamMemberType>
  >(top3MembersUrl, {});
  const podiumMembers = top3Members?.results || [];

  const setSortInQueryString = (sortValue: string) => {
    router
      ?.replace(
        {
          pathname: router.pathname,
          query: { ...router.query, sort: sortValue },
        },
        {
          pathname: router.pathname.replace(
            "[slug]",
            `${(router.query.slug as string) || ""}`,
          ),
          query: { ...router.query, sort: sortValue },
        },
        { shallow: true },
      )
      .catch((err: Error) => {
        trackError(err, { component: "TeamMembersOverview" });
      });
  };

  const handleFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const sortValue = e.target.value as TeamMemberSortType;
    if (!sortValue) return;
    setSortInQueryString(sortValue);
  };

  const joinUrl = isBrowser
    ? `${window.location.origin}${window.location.pathname}/join`
    : "";

  // Superteams don't have team leaders or teamLeaderPages only Teams do
  const isLeaderForMembership = (member: TeamMemberType) =>
    "teamLeaderPage" in member.parentPage &&
    member.parentPage.teamLeaderPage &&
    member.parentPage.teamLeaderPage.uniqueId === member.childPage.uniqueId;
  const leaderBoardSlug = `/team${page.url.slice(
    page.url.lastIndexOf("/"),
  )}/leaderboard`;
  return (
    <>
      {/* Error State */}
      {error ? <Text as="span">Unable to get team members</Text> : null}

      {/* Loading State */}
      {!error && !data ? <Loader /> : null}

      {/* Empty State */}
      {!error && data && !memberships.length ? (
        <Text as="span">There are currently no team members</Text>
      ) : null}

      {/* Data State */}
      {memberships.length ? (
        <div data-component="team-members-overview">
          <Box>
            <Heading>Team Members</Heading>
            {page.leaderboardEnabled ? (
              <div>
                {podiumMembers?.length && (
                  <>
                    <PodiumLinkWrapper>
                      <NextLink
                        href={leaderBoardSlug}
                        data-cta-type="link-leaderboard"
                      >
                        <A as="span">
                          {top3MembersError ? (
                            <Text as="span">Unable to get podium members</Text>
                          ) : (
                            <TeammMembersOverviewPodium
                              members={podiumMembers}
                            />
                          )}
                        </A>
                      </NextLink>
                    </PodiumLinkWrapper>
                    <RowCenter>
                      <Box marginBottom="m">
                        <NextLink href={leaderBoardSlug}>
                          <Button
                            appearance="secondary"
                            as="span"
                            data-cta-type="link-leaderboard"
                          >
                            <IconFa faIcon={faTrophy} />
                            Show leaderboard
                          </Button>
                        </NextLink>
                      </Box>
                    </RowCenter>
                  </>
                )}
              </div>
            ) : null}

            <FilterWrapper>
              <Select
                required
                value={sort}
                label="Sort members by"
                onChange={handleFilterChange}
                aria-controls="memberList"
                hideRequiredInLabel
              >
                <option value="alpha">A-Z</option>
                <option value="amount">amount raised</option>
                <option value="recent">recently joined</option>
                <option value="leader">leader first</option>
              </Select>
            </FilterWrapper>
          </Box>

          <MemberList
            id="memberList"
            aria-live="assertive"
            data-component="team-member-list"
          >
            <ShadowWrapper>
              <Row>
                <AvatarWrapper marginBottom="none">
                  <img src={joinTeamSVG} alt="join team" />
                </AvatarWrapper>
                <ButtonWrapper paddingLeft="xs">
                  <Box marginBottom="xs">
                    <NextLink
                      href={`/team${page.url.slice(
                        page.url.lastIndexOf("/"),
                      )}/join`}
                      onClick={() => {
                        trackEventGtm({ event: "join_cta" });
                      }}
                      data-cta-type="join-team"
                    >
                      <Button
                        appearance="primary"
                        as="span"
                        data-cta-type="join-team"
                      >
                        Join team
                      </Button>
                    </NextLink>
                  </Box>
                  <FullWidthChild>
                    <PopOver
                      modalLabel="invite to team"
                      modalContent={<CopyToClipBoard shareCopy={joinUrl} />}
                      position="bottom"
                      css="width: 100%;"
                    >
                      <Button
                        appearance="secondary"
                        onMouseDown={() => {
                          trackEventGtm({ event: "invite_cta" });
                        }}
                        onTouchStart={() => {
                          trackEventGtm({ event: "invite_cta" });
                        }}
                        data-cta-type="invite-to-team"
                      >
                        Invite to team
                      </Button>
                    </PopOver>
                  </FullWidthChild>
                </ButtonWrapper>
              </Row>
            </ShadowWrapper>
            {!error &&
              memberships
                .filter(
                  (member: TeamMemberType) =>
                    member.childPage.entityType === "FundraisingPage",
                )
                .map((member: TeamMemberType) => (
                  <li key={member.uniqueId}>
                    <MemberFundraisingPageDonate
                      fundraisingPage={member.childPage}
                      isLeader={isLeaderForMembership(member)}
                    />
                  </li>
                ))}
          </MemberList>

          {totalItems > MAX_ITEMS && (
            <Box marginBottom="none" marginTop="s">
              <NextLink
                href={`/team${page?.url?.slice(
                  page.url.lastIndexOf("/"),
                )}/members`}
                data-cta-type="link-team-members"
              >
                <Button data-cta-type="link-team-members">{`See all team members (${totalItems})`}</Button>
              </NextLink>
            </Box>
          )}
        </div>
      ) : null}
    </>
  );
};

export default TeamMembersOverview;
