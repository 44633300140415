import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import NextLink from "next/link";
import { useRouter } from "next/compat/router";
import {
  Heading,
  Box,
  IconFa,
  Totaliser,
  Loader,
  Button,
} from "@cruk/cruk-react-components";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";

import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { queryAsString } from "@fwa/src/utils/urlUtils";
import {
  calculatePercentRounded,
  numberWithCommas,
  formatMoney,
} from "@fwa/src/utils/formatUtils";
import { isPast, isToday } from "@fwa/src/utils/timeUtils";
import {
  fwsUrlTeamSlug,
  updatePage,
  updateTeam,
} from "@fwa/src/services/teamService";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useModalContext } from "@fwa/src/contexts/ModalContext";
import { useBrandContext } from "@fwa/src/contexts/BrandContext";
import { useTracking } from "@fwa/src/hooks/useTracking";
import { sendGTMTrackingEvent } from "@fwa/src/services/gtm";

import { PageTitle } from "@fwa/src/components/PageTitle";
import { ErrorPageWrapper } from "@fwa/src/components/ErrorPageWrapper";
import { Share } from "@fwa/src/components/Share";
import { TeamMembersOverview } from "@fwa/src/components/TeamMembersOverview";
import { TeamPageProgress } from "@fwa/src/components/TeamPageProgress";
import { TeamPageSettings } from "@fwa/src/components/TeamPageSettings";
import { TotalsSection } from "@fwa/src/components/TotalsSection";
import { PageMemberOf } from "@fwa/src/components/PageMemberOf";
import { PageHeaderImage } from "@fwa/src/components/PageHeaderImage";
import { PageSubtitle } from "@fwa/src/components/PageSubtitle ";
import { PageStory } from "@fwa/src/components/PageStory";

import {
  ContentMaxWidth,
  BlockCenter,
  RowStretch,
  HeaderGroup,
  AvatarResponsiveWrapper,
  RowAligned,
} from "@fwa/src/components/styles";

import {
  TotaliserButtonsFlex,
  TeamLogo,
  TeamLabel,
} from "@fwa/src/components/TeamPage/styles";

import {
  type TeamPageType,
  type BrandNameType,
  type PageType,
} from "@fwa/src/types";

const memberTeamCrukSvg = "/assets/images/img/member-team-cruk-128.svg";
const memberTeamSu2cSvg = "/assets/images/img/member-team-su2c-128.svg";
const memberTeamBBSvg = "/assets/images/img/member-team-bb-128.svg";

const teamPageBrandedLogos = {
  cruk: memberTeamCrukSvg,
  rfl: memberTeamCrukSvg,
  su2c: memberTeamSu2cSvg,
  bowelbabe: memberTeamBBSvg,
};

const FeedListFilter = dynamic(
  () => import("@fwa/src/components/FeedListFilter"),
);

type Props = {
  pageData: TeamPageType;
  themeName?: BrandNameType;
};

export const TeamPage = ({ pageData, themeName }: Props) => {
  //  figure out unique page url for page cacheId
  const router = useRouter();
  const { slug } = router?.query || {};
  const slugString = queryAsString(slug);
  const url = slugString.length
    ? `${fwsUrlTeamSlug({ slug: slugString })}`
    : null;
  const { trackEventGtm, trackError } = useTracking();
  const [modalState] = useModalContext();

  const { ref: feedRef, inView: feedInView } = useInView({
    triggerOnce: true,
    rootMargin: "200px",
  });

  // page cache
  const opts = {
    fallbackData: pageData,
    revalidateOnMount: true,
    revalidateIfStale: true,
  };
  const { data: page, error, mutate } = useSwrGet<TeamPageType>(url, opts);

  const [brandName, setBrandName] = useBrandContext();

  useEffect(() => {
    if (!!themeName && themeName !== brandName) {
      setBrandName(themeName);
    }
  }, [themeName, brandName, setBrandName]);

  useEffect(() => {
    sendGTMTrackingEvent({
      event: "pageView",
      pagePath: "/team-page",
      pageTitle: "team page",
    });
  }, []);

  // The SWR get function with the mutate, is from the URL using the a slug
  // which redirects and can't be used for POST requests
  // To update page data via API we need to use a different endpoint
  // and use that output to mutate local data on SWR
  const updateAndMutatePage = async (
    newData: Partial<PageType>,
    refresh?: boolean,
  ) =>
    updatePage({ pageId: page?.uniqueId || "", data: newData })
      .then(
        (updatedData) =>
          mutate(updatedData, refresh || false) as Promise<PageType>,
      )
      .catch((err) => {
        trackError(err as Error, {
          component: "TeamPage",
          function: "updateAndMutatePage",
        });
      });

  // Same as above but uses team endpoint to update things that only exist on team
  // like leaderboard enabled which isn't a generic page attribute
  const updateAndMutateTeam = async (
    newData: Partial<TeamPageType>,
    refresh?: boolean,
  ) =>
    updateTeam({ teamId: page?.uniqueId || "", data: newData })
      .then(
        (updatedData) =>
          mutate(updatedData, refresh || false) as Promise<TeamPageType | void>,
      )
      .catch((err) => {
        trackError(err as Error, {
          component: "TeamPage",
          function: "updateAndMutateTeam",
        });
      });

  // this is when we want to mutate the page but the data is set via an alternative api endpoint
  // for example setting the header image which has its own endpoint to create an image entity before attaching to a page
  // but header image data still appears in page data.
  const mutatePage = (pageDataToMutate: Partial<PageType>) =>
    mutate({ ...page, ...pageDataToMutate }, false) as Promise<PageType | void>;

  const [fundraiserState] = useFundraiserContext();
  const percentageOfTarget = page
    ? calculatePercentRounded(page.donationsTotalAmount, page.target)
    : 0;
  const target = numberWithCommas(
    formatMoney(page?.target ? parseFloat(`${page.target}`) : 0),
  );
  const canEdit: boolean =
    !!fundraiserState?.fundraiser?.uniqueId &&
    fundraiserState?.fundraiser?.uniqueId ===
      page?.teamLeaderPage?.fundraiser?.uniqueId;
  const hasTarget = page?.target && page.target > 0;
  const pageCloseDate = page?.closeDate ? new Date(page.closeDate) : null;
  const isPageClosed: boolean =
    page?.pageStatus === "closed" ||
    (pageCloseDate && (isToday(pageCloseDate) || isPast(pageCloseDate))) ||
    false;

  return (
    <ErrorPageWrapper error={error!}>
      {/* Display modal */}
      {modalState}
      <ContentMaxWidth marginBottom="xxl">
        {/* Loading state */}
        {!page ? <Loader /> : null}
        {/* Data state */}
        {/* Top actions block */}
        {page ? (
          <>
            <Box
              backgroundColor="backgroundLight"
              padding="xs"
              marginBottom="none"
            >
              <RowStretch data-component="team-actions-top">
                <Share
                  page={page}
                  feedItem={null}
                  isOwner={canEdit}
                  popoverPosition="bottom"
                  ctaLocation="header"
                >
                  <Button
                    type="button"
                    appearance="secondary"
                    data-cta-type="open-share"
                  >
                    <IconFa faIcon={faShareAlt} />
                    Share
                  </Button>
                </Share>

                <NextLink
                  href={`/team/${slugString}/members`}
                  onClick={() => {
                    trackEventGtm({
                      event: "donate_cta",
                      cta: "header",
                    });
                  }}
                  data-cta-type="donate"
                >
                  <Button appearance="primary" as="span" data-cta-type="donate">
                    Donate
                  </Button>
                </NextLink>
              </RowStretch>
            </Box>
            <Box
              backgroundColor="backgroundLight"
              paddingVertical="s"
              paddingHorizontal="none"
              margin="none"
            >
              <AvatarResponsiveWrapper>
                <RowAligned>
                  <TeamLogo src={teamPageBrandedLogos[brandName]} alt="" />
                  <TeamLabel marginLeft="xs" marginBottom="none">
                    Team page
                  </TeamLabel>
                </RowAligned>
              </AvatarResponsiveWrapper>
            </Box>
            {/* Title block */}
            <HeaderGroup>
              <PageTitle
                canEdit={canEdit}
                page={page}
                isTeam
                updateAndMutatePage={updateAndMutatePage}
              />
              {/* Header image  */}
              <Box marginBottom="none" data-component="team-header-image">
                <PageHeaderImage
                  canEdit={canEdit}
                  page={page}
                  mutatePage={mutatePage}
                />
              </Box>
            </HeaderGroup>
          </>
        ) : null}
        {page ? (
          <>
            {/* totaliser block */}
            <Box
              backgroundColor="lightBackground"
              marginBottom="none"
              data-component="totaliser"
            >
              <BlockCenter>
                <Totaliser
                  isCompact={false}
                  target={hasTarget ? page.target : undefined}
                  total={page.donationsTotalAmount}
                  giftAid={page.donationsGiftAidTotalAmount}
                  summaryMessage={
                    hasTarget
                      ? `${percentageOfTarget}% of £${target} target`
                      : undefined
                  }
                />
              </BlockCenter>
            </Box>
            {/* Edit fundraising target */}
            {/* Design for OF-5985 requires an editable team target but API doesn't support yet. Tom m is looking into it. 
            <Box backgroundColor="backgroundLight" marginBottom="none">
              <DashedBorder>
                <EditableTargetForm
                  fieldName="target"
                  text={page?.target?.toString()}
                  totaliserProps={{
                    isCompact: false, 
                    target: page.target,
                    total: page.donationsTotalAmount,
                    giftAid: page.donationsGiftAidTotalAmount,
                    summaryMessage: `${percentageOfTarget}% of £${target} target`,
                  }}
                  handleEditData={updateAndMutatePage}
                />
              </DashedBorder>
            </Box> */}
            {/* Share and donate btns */}
            <TotaliserButtonsFlex data-component="totaliser-ctas">
              <Share
                page={page}
                feedItem={null}
                isOwner={canEdit}
                popoverPosition="top"
                ctaLocation="totaliser"
              >
                <Button
                  type="button"
                  appearance="secondary"
                  data-cta-type="open-share"
                >
                  <IconFa faIcon={faShareAlt} />
                  Share this team
                </Button>
              </Share>
              {!isPageClosed && (
                <NextLink
                  href={`/team/${slugString}/members`}
                  onClick={() => {
                    trackEventGtm({
                      event: "donate_cta",
                      cta: "totaliser",
                    });
                  }}
                  data-cta-type="donate"
                >
                  <Button appearance="primary" as="span" data-cta-type="donate">
                    Donate to team
                  </Button>
                </NextLink>
              )}
            </TotaliserButtonsFlex>
            <Box paddingTop="s" backgroundColor="lightBackground">
              <PageMemberOf page={page} />
            </Box>
            {/* Page settings section */}
            {canEdit ? (
              <div id="team-settings">
                <TeamPageSettings
                  page={page}
                  updateAndMutatePage={updateAndMutatePage}
                  updateAndMutateTeam={updateAndMutateTeam}
                />
                <TeamPageProgress page={page} />
              </div>
            ) : null}
            {/* Sub Heading Block */}

            <PageSubtitle
              page={page}
              canEdit={canEdit}
              updateAndMutatePage={updateAndMutatePage}
              isTeam
            />

            {/* members block */}
            <Box id="members" backgroundColor="lightBackground">
              <TeamMembersOverview page={page} />
            </Box>
            {/* story block */}
            <PageStory
              page={page}
              updateAndMutatePage={updateAndMutatePage}
              canEdit={canEdit}
              isTeam
            />
          </>
        ) : null}
        {/* Feed block */}
        {/* Is owner is false because the feed items come from different fundraiser pages so they are never editable in a teams page */}
        {!!page && (page.donationsCount || page.postsCount) ? (
          <div ref={feedRef} data-component="latest-updates">
            <Box backgroundColor="lightBackground" marginBottom="xxl">
              <Heading h2>Latest updates</Heading>
              {feedInView && (
                <FeedListFilter
                  pageId={page.uniqueId}
                  canCreate={false}
                  feedItemTotals={{
                    feeditems: page.donationsCount + page.postsCount,
                    donations: page.donationsCount,
                    posts: page.postsCount,
                    activitys: 0,
                    milestones: 0,
                  }}
                  pageUrl={url}
                  showParentPageLink
                />
              )}
              <TotalsSection
                donationsTotalAmount={page.donationsTotalAmount}
                donationsTotalAmountFake={page.donationsTotalAmountFake}
                donationsTotalAmountFacebook={page.donationsTotalAmountFacebook}
              />
            </Box>
          </div>
        ) : null}
      </ContentMaxWidth>
    </ErrorPageWrapper>
  );
};

export default TeamPage;
