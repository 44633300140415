import NextLink from "next/link";
import { useRouter } from "next/router";
import {
  Box,
  Text,
  Heading,
  Button,
  IconFa,
} from "@cruk/cruk-react-components";

import { CollapsableSection } from "@fwa/src/components/CollapsableSection";
import { EditableUrlForm } from "@fwa/src/components/EditableUrlForm";
import { OnlyYou } from "@fwa/src/components/OnlyYou";

import { TopBorderChunky, Border } from "@fwa/src/components/styles";
import { FlexWrap } from "@fwa/src/components/TeamPageSettings/styles";

import { type PageType, type TeamPageType } from "@fwa/src/types";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { useTracking } from "@fwa/src/hooks/useTracking";

type Props = {
  page: TeamPageType;
  updateAndMutatePage: (data: Partial<PageType>) => Promise<void | PageType>;
  updateAndMutateTeam: (
    data: Partial<TeamPageType>,
  ) => Promise<void | TeamPageType>;
};

export const TeamPageSettings = ({
  page,
  updateAndMutatePage,
  updateAndMutateTeam,
}: Props) => {
  const { trackError } = useTracking();
  const { leaderboardEnabled } = page;

  const router = useRouter();
  const urlAnchorHashTagString = router.asPath.split("#")?.[1] || "";
  const isOpen = urlAnchorHashTagString === "team-settings";

  const toggleEnableLeaderboard = () => {
    updateAndMutateTeam({
      leaderboardEnabled: !leaderboardEnabled,
    }).catch((err: Error) => {
      trackError(err, {
        component: "TeamPageSettings",
        function: "leaderboardEnabled",
      });
    });
  };

  return (
    <TopBorderChunky data-component="team-page-settings">
      <Box backgroundColor="backgroundLight" paddingBottom="s">
        <CollapsableSection
          id="page-settings"
          headerTitleText="Page settings"
          headerIcon={<IconFa faIcon={faGear} size="1.5em" color="primary" />}
          startOpen={isOpen}
        >
          <Box paddingTop="s" paddingBottom="none">
            <OnlyYou />
            <Box marginBottom="s">
              <div data-component="edit-url-form">
                <EditableUrlForm
                  page={page}
                  handleEditData={updateAndMutatePage}
                  route="team"
                />
              </div>
            </Box>
            <Box marginBottom="s">
              <Border>
                <FlexWrap>
                  <Box marginBottom="xxs">
                    <Heading h2 textSize="xl">
                      Edit team members
                    </Heading>
                    <Text>Remove or change members of your team</Text>
                  </Box>
                  <NextLink
                    href={`/team${page.url.slice(
                      page.url.lastIndexOf("/"),
                    )}/members/edit`}
                    data-cta-type="link-team-members"
                  >
                    <Button
                      appearance="secondary"
                      as="span"
                      data-cta-type="link-team-members"
                    >
                      Edit team members
                    </Button>
                  </NextLink>
                </FlexWrap>
              </Border>
            </Box>
            <Box>
              <Border>
                <FlexWrap>
                  <Box marginBottom="xxs">
                    <Heading h2 textSize="xl">
                      Fundraiser leaderboard
                    </Heading>
                    <Text>
                      Control whether or not the fundraising leaderboard appears
                      for your team
                    </Text>
                  </Box>
                  <Button
                    appearance="secondary"
                    onClick={toggleEnableLeaderboard}
                    data-cta-type="edit-leaderboard-visibility"
                  >
                    {leaderboardEnabled
                      ? "Remove leaderboard"
                      : "Show leaderboard"}
                  </Button>
                </FlexWrap>
              </Border>
            </Box>
          </Box>
        </CollapsableSection>
      </Box>
    </TopBorderChunky>
  );
};

export default TeamPageSettings;
