import { type ChangeEvent, useState } from "react";
import {
  Text,
  Heading,
  Button,
  TextField,
  Link,
  Box,
  IconFa,
} from "@cruk/cruk-react-components";
import { faArrowRight, faLink } from "@fortawesome/free-solid-svg-icons";

import { calcLength } from "@fwa/src/utils/formUtils";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { fwsUrlCheckUrl } from "@fwa/src/services/fundraisingPageService";
import { fetcher, FWA_BASE_URL } from "@fwa/src/services/apiClient";
import { useTracking } from "@fwa/src/hooks/useTracking";
import { PAGE_URL_MAX_LENGTH, validatePageUrl } from "@fwa/src/validation/page";

import { Editable } from "@fwa/src/components/Editable";

import { Border, ArrowWrapper } from "@fwa/src/components/styles";

import { type CheckUrlType, type PageType } from "@fwa/src/types";

// from FWS page.url is actually a `path` like `team/1111`
// in the form, the user can see an edit the `slug` for example `1111`, we only validate against the slug
// when submitting the data to FWS we just send sumbit the `urlPath` with the new path `/team/2222`
export const EditableUrlForm = ({
  page,
  handleEditData,
  route,
}: {
  page: PageType;
  handleEditData: (
    data: Partial<PageType>,
  ) => Promise<void | undefined | PageType>;
  route: "page" | "team";
}) => {
  const { trackError } = useTracking();
  const currentSlug = page.url.slice(page.url.lastIndexOf("/") + 1);
  const [slugState, setSlugState] = useState<string>(currentSlug);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [urlSuggestion, setUrlSuggestion] = useState<string>("");

  const handleSubmit = async (): Promise<void | PageType> => {
    // validate slug
    const validationResultString = validatePageUrl(slugState);
    setValidationMessage(validationResultString || "");
    // if invalid, stop
    if (validationResultString) return Promise.resolve();
    // check if url is unique
    // when checking if the url is unique we need send the full `url` to fwsUrlCheckUrl()
    // this will fail if you are looking at a local or PR deploy
    // as the data it checks agains will be the full url on the production or integration database
    setIsLoading(true);
    const newUrl = `${FWA_BASE_URL}/${route}/${slugState}`;
    const isUrlUnique = await fetcher(fwsUrlCheckUrl({ url: newUrl }))
      .then((res) => res as CheckUrlType)
      .then((data: CheckUrlType) => {
        if (data.isUnique) {
          setUrlSuggestion("");
          return true;
        } else {
          setUrlSuggestion(data.url);
          setValidationMessage(
            "That link is already in use, please try another.",
          );
          setIsLoading(false);
          return false;
        }
      })
      .catch((error) => {
        setValidationMessage("Unable to check link");
        setIsLoading(false);
        trackError(error as Error, {
          component: "EditableDisplayNameForm",
          function: "isUrlUnique",
        });
      });

    // if url is not unique, stop
    if (!isUrlUnique) return Promise.resolve();

    // sumbit data
    return handleEditData({
      urlPath: `/${route}/${slugState}`,
    })
      .then(() => {
        setIsLoading(false);
        return;
      })
      .catch((error) => {
        setValidationMessage("Unable to submit");
        setIsLoading(false);
        trackError(error as Error, {
          component: "EditableDisplayNameForm",
          function: "handleSubmit",
        });
        return;
      });
  };

  const handleBlur = () => {
    const validationResultString = validatePageUrl(slugState);
    setValidationMessage(validationResultString || "");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSlugState(e.target.value);
  };

  const handleSuggestionClick = () => {
    const suggestedSlug = urlSuggestion.split(`/${route}/`)[1];
    setSlugState(suggestedSlug);
    setValidationMessage("");
    setUrlSuggestion("");
  };

  if (!page) return null;

  return (
    // FWS can't handle setting a custom URL more than once
    // so we need to check if the URL has already been set with previous url
    <>
      {page.previousUrl ? (
        <Box data-component="page-url">
          <Heading h2 textSize="xl">
            <Box as="span" marginRight="xxs">
              <IconFa faIcon={faLink} />
            </Box>
            Link
          </Heading>
          <Text wordBreak="break-all">
            <Link
              wordBreak="break-all"
              href={page.url}
              data-cta-type="current-page-url"
            >
              {page.url}
            </Link>
          </Text>
          <Text>
            Spread the word - share this page with your friends, family and
            supporters.
          </Text>
        </Box>
      ) : (
        <Editable
          fieldName="urlPath"
          isLoading={isLoading}
          editButtonHidden
          editNode={
            <>
              <Heading h2 textSize="xl">
                <Box as="span" marginRight="xxs">
                  <IconFa faIcon={faLink} />
                </Box>
                Link
              </Heading>
              <Text>
                You may edit the link only once. Once your Link has been
                changed, it can not be changed again.
              </Text>
              <Text>Can only contain letters, numbers and hyphens.</Text>
              <>
                <TextField
                  name="URL"
                  value={slugState}
                  label="Link"
                  aria-label="Link"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errorMessage={validationMessage}
                  maxLength={PAGE_URL_MAX_LENGTH}
                  extraTop={
                    <Text wordBreak="break-all">
                      {`${isBrowser ? window.location.origin : ""}/page/`}
                    </Text>
                  }
                />
                <Text textAlign="right" marginTop="xxs">{`${
                  PAGE_URL_MAX_LENGTH - calcLength(slugState)
                } characters remaining`}</Text>
              </>
              {urlSuggestion.length ? (
                <Text role="alert" marginBottom="xs">
                  {`Available: `}
                  <Button
                    appearance="tertiary"
                    onClick={handleSuggestionClick}
                    data-cta-type="set-suggested-url"
                  >
                    {urlSuggestion}
                  </Button>
                </Text>
              ) : null}
            </>
          }
          viewNode={
            <Border>
              <ArrowWrapper>
                <IconFa faIcon={faArrowRight} />
              </ArrowWrapper>
              <Heading h2 textSize="xl" marginTop="none">
                <Box as="span" marginRight="xxs">
                  <IconFa faIcon={faLink} />
                </Box>
                Link
              </Heading>
              <Text marginBottom="none">{`Customise the link for your ${
                route === "team" ? "Team" : "Giving"
              } Page`}</Text>
            </Border>
          }
          handleSubmit={handleSubmit}
          tooltip="Edit link"
        />
      )}
    </>
  );
};

export default EditableUrlForm;
